import React, {useEffect, useRef, useState} from "react"
import PropTypes from "prop-types"

import {useHistory, useLocation} from "react-router-dom";
import {useFormContext} from "../customHooks/useFormContext";

import _ from "lodash";
import QuoteService from "../../services/APIService";
import UtilService from "../../services/UtilService";
import planTypeList from "../../assets/data/planTypeList.json"
import moment from "moment";
import {v4 as uuid} from "uuid";
import {Container, Grid, Typography} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import Drawer from "@material-ui/core/Drawer";
import HelpDrawer from "../modal/HelpDrawer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import SidePanel from "../customComponents/SidePanel";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import lifeTypeList from "../../assets/data/lifeTypeList.json";
import {wildcardMatch} from "../functions/wildcardMatch";
import InfoIcon from "@material-ui/icons/Info";
import Snackbar from "@material-ui/core/Snackbar";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Icon from "@material-ui/core/Icon";
import MatitalStatusIcon from "../../assets/images/icons/wedding-ring.svg";
import InputAdornment from "@material-ui/core/InputAdornment";
import BrownSenseLogo from "../../../src/assets/images/Brownsense_Logo.png";
import {API_CONFIG} from "../../config/api-config";
import waIcon from "../../../src/assets/images/icons/WhatsAppIcon.png"
import secureIcon from "../../../src/assets/images/icons/secure.svg"
import flexiblePaymentOptionsList from "../../assets/data/flexiblePaymentOptionsList.json";


const SidePanelLayout = ({children}) => {
    const [
        values,
        errors,
        resetState,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit
    ] = useFormContext(null, null);
    const location = useLocation();
    const currentStateRef = useRef();
    const [hidePageHeaderTitle, setHidePageHeaderTitle] = useState(false);
    const prevTotalPremiumRef = useRef(null);
    const prevTotalPremium = prevTotalPremiumRef.current;
    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dialogType, setDialogType] = React.useState();
    const [dialogActionStatus, setDialogActionStatus] = React.useState(null);
    const [dialogActionStatusShow, setDialogActionStatusShow] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {promiseInProgress} = usePromiseTracker();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [warningError, setWarningError] = useState(null);
    const [withinBusinessHours, setWithinBusinessHours] = React.useState(true);
    useEffect(() => {

        if (wildcardMatch(location.pathname.toString().toLowerCase(), "/quote/*")) {
            setHidePageHeaderTitle(false);

        } else if (wildcardMatch(location.pathname.toString().toLowerCase(), "/buy/*")) {
            setHidePageHeaderTitle(true);

        } else {
            setHidePageHeaderTitle(false);

        }

    }, [location.pathname])

    const goToPlanner = () => {
        window.open(API_CONFIG.FUNERALPLANNER_URL)
    }
    const handleDialogOpen = (event) => {
        setDialogType(event);
        setOpenDialog(true)
    }
    const handleCloseSnackBar = () => {
        setDialogActionStatusShow(false)
    }

    const handleAddNewLife = () => {
        let tempValues = _.cloneDeep(values);
        tempValues.app_state.beenToSummary = true;
        if ((typeof values.root_state.additionalLives !== "undefined") && (values.root_state.additionalLives !== null) && (values.root_state.additionalLives.length > 0)) {
            tempValues.app_state.additionalLifeIndex = values.root_state.additionalLives.length - 1;
        } else {
            tempValues.app_state.additionalLifeIndex = -1;
        }

        tempValues.app_state.currentStep = '/quote/add-life';

        setValues(tempValues);
        //history.push('/quote/add-life');
    }

    const handleDialogClose = (status, temp_Values, step) => {
        if ((typeof status !== "undefined") && (status !== null)) {
            setDialogActionStatus(status)
            if ((typeof step !== "undefined") && (step === "buyNow")) {
                ConfirmQuote(temp_Values);
            } else {
                setDialogActionStatusShow(true)
            }
        }
        setWithinBusinessHours(UtilService.isWithinWorkingHours())
        if ((typeof temp_Values !== "undefined") && (temp_Values !== null)) {
            setValues(temp_Values);
        }
        setOpenDialog(false)
    }
    const getInfo = () => {
        window.open(API_CONFIG.SHOPFRONT_URL.concat("product/funeral-cover/"));
    }
    const renderStatus = () => {
        if (loading || promiseInProgress) return "...";
        let price = (typeof values.root_state.totalPremiumForInsuredLives !== "undefined")
            ? 'R ' + values.root_state.totalPremiumForInsuredLives.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            : "Price Error!";
        if (error) {
            return "Oops! Premium failed to generate... Chat with us in the bottom right corner of your screen."
        }
        if (values.root_state.totalPremiumForInsuredLives) return price;
    }

    const getTotalNumberOfMembers = () => {
        let total = 0;
        if ((typeof values.root_state.mainMember.coverAmount !== "undefined") && (values.root_state.mainMember.coverAmount !== null) && (values.root_state.mainMember.coverAmount > 0)) {
            total += 1;
        }
        if ((typeof values.root_state.additionalLives !== "undefined") && (values.root_state.additionalLives.length > 0)) {
            values.root_state.additionalLives.forEach((item, index) => {
                if ((typeof item.coverAmount !== "undefined") && (item.coverAmount !== null) && (item.coverAmount > 0)) {
                    total += 1;
                }
            })
        }
        return total
    }

    const getTotalCoverAmount = () => {
        let total = 0;
        if ((typeof values.root_state.mainMember.coverAmount !== "undefined") && (values.root_state.mainMember.coverAmount !== null) && (values.root_state.mainMember.coverAmount > 0)) {
            total += values.root_state.mainMember.coverAmount
        }

        if ((typeof values.root_state.additionalLives !== "undefined") && (values.root_state.additionalLives.length > 0)) {
            values.root_state.additionalLives.forEach((item, index) => {
                if ((typeof item.coverAmount !== "undefined") && (item.coverAmount !== null) && (item.coverAmount > 0)) {
                    total += item.coverAmount;
                }
            })
        }
        return total

        // return "R " + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    }
    const handleToCart = (e) => {
        e.preventDefault();
        let temp_Values = _.cloneDeep(values);
        temp_Values.app_state.currentStep = '/quote/cart';
        setValues(temp_Values);
        //history.push('/quote/cart');
    }
    const toggleDrawer = (event) => {
        //console.log("Toggle Drawer")
        //console.log(openDrawer)
        setOpenDrawer(!openDrawer);

    }

    const goToEnrol = e => {
        e.preventDefault();

        if(values.app_state.performOTPValidation){
            if ((UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) !== -1) && (values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone)].validated === true)) {
                ConfirmQuote();
            } else {
                handleDialogOpen("validateMe")
            }
        }else {
            ConfirmQuote();
        }

    }

    const ConfirmQuote = (input_temp_Values) => {
        let temp_Values;
        if ((typeof input_temp_Values !== "undefined") && (input_temp_Values !== null)) {
            temp_Values = _.cloneDeep(input_temp_Values);
        }
        else {
            temp_Values = _.cloneDeep(values)
        }
        temp_Values.app_state.currentStep = '/buy/step-1';
        setValues(temp_Values);
    }



    useEffect(() => {

        if ((values.app_state.currentStep.toString().toLowerCase() === "/quote/add-life") || (values.app_state.currentStep.toString().toLowerCase() === "/quote/benefits") || (values.app_state.currentStep.toString().toLowerCase() === "/quote/cart")) {
            //console.log("Inside needed pages")
            if (JSON.stringify(currentStateRef.current) !== JSON.stringify(values.root_state)) {
                if (!prevTotalPremium) {
                    prevTotalPremiumRef.current = values.root_state.totalPremiumForInsuredLives;
                }
                //console.log("Previous state not the same")
                if ((((values.root_state.mainMember.age >= lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", values.root_state.mainMember.lifeType)].overAllMinAge) &&
                        (values.root_state.mainMember.age <= lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", values.root_state.mainMember.lifeType)].overAllMaxAge))
                ) && (((values.root_state.additionalLives.length > 0 && (values.root_state.additionalLives.filter((life) => {
                    return life.coverAmount !== null;
                }).length === values.root_state.additionalLives.length) && (values.root_state.additionalLives.filter((life) => {
                    return ((life.age >= lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", life.lifeType)].overAllMinAge) && (life.age <= lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", life.lifeType)].overAllMaxAge));
                }).length === values.root_state.additionalLives.length)) || ((values.root_state.additionalLives.length === 0) && (values.root_state.mainMember.coverAmount !== null) )))) {
                    //console.log("inside check")
                    if ((typeof currentStateRef.current === "undefined") || ((typeof currentStateRef.current !== "undefined") &&
                        ((currentStateRef.current.cashBack !== values.root_state.cashBack)
                            || (currentStateRef.current.inflation !== values.root_state.inflation)
                            || (currentStateRef.current.valueProtectionBenefit !== values.root_state.valueProtectionBenefit)
                            || (currentStateRef.current.totalCoverForInsuredLives !== values.root_state.totalCoverForInsuredLives)
                            || ((currentStateRef.current.additionalLives.length !== values.root_state.additionalLives.length) && (values.root_state.additionalLives.filter((life) => {
                                return life.coverAmount !== null;
                            }).length === values.root_state.additionalLives.length) && (values.root_state.additionalLives.filter((life) => {
                                return ((life.age >= lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", life.lifeType)].overAllMinAge) && (life.age <= lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", life.lifeType)].overAllMaxAge));
                            }).length === values.root_state.additionalLives.length))
                            || (currentStateRef.current.mainMember.coverAmount !== values.root_state.mainMember.coverAmount)
                            || (currentStateRef.current.mainMember.age !== values.root_state.mainMember.age)))) {
                        /*|| ((JSON.stringify(currentStateRef.current.additionalLives) !== JSON.stringify(values.root_state.additionalLives)) )
        */
                        RequestQuote();
                    }
                }

            }
        }


        //prevTotalPremium,values
    }, [values]);

    const RequestQuote = () => {
        trackPromise(
            getQuote()
        ).then();
    }


    function getQuote() {
        setError(null)
        return QuoteService.GetQuote(values)
            .then(r => {
                let payload = r.data;

                if ((typeof payload !== "undefined") && (typeof payload.response !== "undefined") && (typeof payload.success !== "undefined") && (payload.success === true)) {
                    //console.log((typeof payload !== "undefined") && (typeof payload.response !== "undefined") && (typeof payload.success !== "undefined") && (payload.success === true))

                    let response = payload.response;
                    let temp_Values = _.cloneDeep(values);
                    temp_Values.root_state.quotePackageId = response.quoteNumber;
                    temp_Values.root_state.totalPremiumForInsuredLives = response.totalPremium;
                    if ((typeof response.benefits !== "undefined") && (response.benefits.length > 0)) {
                        response.benefits.forEach((benefit, index) => {

                            if ((typeof benefit.benefitIdentifier !== "undefined") && (typeof benefit.benefitIdentifier.benefitCode !== "undefined") ){
                                if (benefit.benefitIdentifier.benefitCode === "MFMM") {
                                    temp_Values.root_state.mainMember.premium = benefit.premium;
                                }
                                else  if (benefit.benefitIdentifier.benefitCode === "MFUC") {
                                    if ((typeof benefit.children !== "undefined") && (benefit.children.length > 0)) {
                                        let firstChild = true;
                                         benefit.children.forEach((child, index) => {
                                            if ((typeof child.rolePlayerId !== "undefined") && !isNaN(child.rolePlayerId)) {
                                                 if (UtilService.findByAttr(temp_Values.root_state.additionalLives, "rolePlayerId", child.rolePlayerId) !== -1){
                                                     if (firstChild) {
                                                         temp_Values.root_state.additionalLives[UtilService.findByAttr(temp_Values.root_state.additionalLives, "rolePlayerId", child.rolePlayerId)].premium = benefit.premium;
                                                         firstChild = false;
                                                     }
                                                     else {
                                                         temp_Values.root_state.additionalLives[UtilService.findByAttr(temp_Values.root_state.additionalLives, "rolePlayerId", child.rolePlayerId)].premium = null;
                                                     }
                                                }
                                            }
                                        });
                                    }
                                }
                                else {
                                    if ((typeof benefit.lives !== "undefined") && (benefit.lives.length > 0)) {
                                        benefit.lives.forEach((life, index) => {
                                            if ((typeof life.rolePlayerId !== "undefined") && !isNaN(life.rolePlayerId)) {
                                                if (UtilService.findByAttr(temp_Values.root_state.additionalLives, "rolePlayerId",life.rolePlayerId) !== -1){
                                                    temp_Values.root_state.additionalLives[UtilService.findByAttr(temp_Values.root_state.additionalLives, "rolePlayerId",life.rolePlayerId)].premium = benefit.premium
                                                }
                                            }
                                        })
                                    }
                                }
                            }
                        });
                    }

                    /*response.premiumsPerInsuredLife.forEach((item, index) => {
                        if (item.funeralBenefitType === "policyowner") {
                            temp_Values.root_state.mainMember.premium = item.funeralBenefitPremium;
                            temp_Values.root_state.mainMember.basePremium = item.funeralBenefitBasePremium;
                            temp_Values.root_state.mainMember.lifeHackPremium = item.funeralBenefitLifehackPremium;
                        } else {
                            let additionalLifeIndex = UtilService.findByAttr(temp_Values.root_state.additionalLives, "uniqueLifeIdentifier", item.uniqueIdentifier);
                            temp_Values.root_state.additionalLives[additionalLifeIndex].premium = item.funeralBenefitPremium;
                            temp_Values.root_state.additionalLives[additionalLifeIndex].basePremium = item.funeralBenefitBasePremium;
                            temp_Values.root_state.additionalLives[additionalLifeIndex].lifeHackPremium = item.funeralBenefitLifehackPremium;
                        }
                    });

                    let totalCoverAmount = getTotalCoverAmount();
                    if (temp_Values.root_state.totalCoverForInsuredLives !== totalCoverAmount) {
                        temp_Values.root_state.totalCoverForInsuredLives = totalCoverAmount;

                    }

                    temp_Values.root_state.error = response.error;*/
                    setValues(temp_Values);
                }
                else {
                    setError("Oops... Something went wrong. Please try again later, or chat with us at the bottom right corner of your screen.")
                    let tempValues = _.cloneDeep(values);

                    if ((typeof tempValues.root_state.additionalLives !== "undefined") && (tempValues.root_state.additionalLives.length > 0)) {
                        tempValues.root_state.additionalLives.forEach((item, index) => {
                            item.premium = 0.00;
                        })
                    }
                    tempValues.root_state.totalPremiumForInsuredLives = 0.00;
                    tempValues.root_state.mainMember.premium = 0.00;
                    setValues(tempValues);
                }
            })
            .catch(error => {
                //console.log("Error : " + JSON.stringify(error))
                setError("Oops... Something went wrong. Please try again later, or chat with us at the bottom right corner of your screen.")
                let tempValues = _.cloneDeep(values);
                tempValues.root_state.quotePackageId = null;
                if ((typeof tempValues.root_state.additionalLives !== "undefined") && (tempValues.root_state.additionalLives.length > 0)) {
                    tempValues.root_state.additionalLives.forEach((item, index) => {
                        item.premium = 0.00;
                    })
                }
                tempValues.root_state.totalPremiumForInsuredLives = 0.00;
                tempValues.root_state.mainMember.premium = 0.00;
                setValues(tempValues);
                if (error.response) {
                    /*
                     * The request was made and the server responded with a
                     * status code that falls out of the range of 2xx
                     */
                    if ((typeof error.response.data.error.validationErrors !== "undefined") && (error.response.data.error.validationErrors.length > 0)) {
                        if (error.response.data.error.validationErrors[0].code === "P29") {
                            setWarningError("The total cover amount selected is below the minimum we allow clients to take out. Have a look at our Funeral Planner app to see if your selected amount will cover all you want for your or your loved one's funeral");
                            setError("")
                        } else {
                            if ((error.response.data.error.validationErrors[0].code === "B1") && (values.coverFor === "OtherPeople") && ((typeof values.relMembers === "undefined") || ((typeof values.relMembers !== "undefined") && (values.relMembers.length === 0)))) {
                                setWarningError("It seems all covered lives have been removed from your plan. To generate a quote, please add additional lives");

                            } else {
                                if (error.response.data.error.validationErrors[0].code === "P20") {
                                    setWarningError("It seems you have added more than 20 lives (excluding children) to this plan. We only allow for up to 20 lives to be added (including yourself). Unlimited children count as a single life.");

                                } else {
                                    setError("Oops... Something went wrong. Please try again later, or chat with us at the bottom right corner of your screen.");
                                     }

                            }
                        }

                    } else if (typeof error.response.data.message !== "undefined") {
                        //setError(error.response.data.message)
                        setError("Oops... Something went wrong. Please try again later, or chat with us at the bottom right corner of your screen.")

                    } else {
                        setError("Oops... Something went wrong. Please try again later, or chat with us at the bottom right corner of your screen.")

                    }

                } else if (error.request) {
                    /*
                     * The request was made but no response was received, `error.request`
                     * is an instance of XMLHttpRequest in the browser and an instance
                     * of http.ClientRequest in Node.js
                     */

                    setError("Oops... Something went wrong. Please try again later, or chat with us at the bottom right corner of your screen.")

                } else {
                    // Something happened in setting up the request and triggered an Error

                    setError("Oops... Something went wrong. Please try again later, or chat with us at the bottom right corner of your screen.")

                }

            })
            .finally(() => {
                    setLoading(false);
                    currentStateRef.current = _.cloneDeep(values.root_state);
                    window.scrollTo(0, 0);
                }
            )
    }

    return (
        <React.Fragment>

            <Container className={"mainContent"} maxWidth="lg"
                       style={!mobileScreen ? {marginTop: "17px", marginBottom: "60px"} : {marginBottom: "60px"}}
            >


                {((!values.app_state.beenToSummary) && (values.app_state.currentStep === "/")) &&
                    <Grid item xs={12} style={(mobileScreen) ? {marginTop: "17px"} : {marginTop: 0}}>
                        <div>
                            <Typography variant="h2" component="h2"
                                        className={"quizHeaderProductName"} display="block"

                        >Metropolitan </Typography>
                        <Typography variant="h2" component="h2" className={`quizHeaderBenefitName ${((!values.app_state.beenToSummary) && ((values.app_state.currentStep === "/") || (values.app_state.currentStep === "/quote/cart") ) && ((values.root_state.additionalInfo.leadProvider !== null) && (values.root_state.additionalInfo.leadProvider.length > 0) && (values.root_state.additionalInfo.leadProvider === 'INFOBYTE_BROWNSENSE'))) && 'brownSense'}`} display="block"

                        >{(values.app_state.fusion === false) ? "FUNERAL PLAN" : "FUNERAL FUSION"} </Typography></div>
                </Grid>}

                {!hidePageHeaderTitle && (
                    <Grid hidden={(location.pathname.toString().toLowerCase() === "/success")} container spacing={3}>
                        <Grid hidden={(location.pathname.toString().toLowerCase() === "/success")} item xs={12} sm={12}
                              md={12} lg={(location.pathname.toString().toLowerCase() !== "/success") ? 8 : 12}
                              className={"noPaddingGrid"}>
                            {((!values.app_state.beenToSummary) && (values.root_state.additionalInfo.leadProvider !== null) && (values.root_state.additionalInfo.leadProvider.length > 0) && (values.root_state.additionalInfo.leadProvider === 'INFOBYTE_BROWNSENSE') && ((location.pathname.toString().toLowerCase() === "/") || (location.pathname.toString().toLowerCase() === "/quote/cart")))&&
                                <Icon>
                                    <img alt="brown sense logo" className={"leadProviderLogo"}
                                         src={BrownSenseLogo}/>
                                </Icon>}


                            {((location.pathname.toString().toLowerCase() === "/") || (location.pathname.toString().toLowerCase() === "/quote/about-you/detail")) ?
                                (<React.Fragment><Typography variant="h2" component="h2"
                                                             className={`quizHeaderText ${((!values.app_state.beenToSummary) && (values.app_state.currentStep === "/")) && (!mobileScreen) && 'noPadding'}  ${((values.app_state.beenToSummary === false) && ((values.app_state.currentStep === "/") || (values.app_state.currentStep === "/quote/cart") ) && ((values.root_state.additionalInfo.leadProvider !== null) && (values.root_state.additionalInfo.leadProvider.length > 0) && (values.root_state.additionalInfo.leadProvider === 'INFOBYTE_BROWNSENSE'))) && 'brownSenseHeader'}`}
                                                             display="block"
                                                             gutterBottom
                                > {((values.root_state.additionalInfo.leadProvider !== null) && (values.root_state.additionalInfo.leadProvider.length > 0) && (values.app_state.beenToSummary === false)) ? ((values.root_state.additionalInfo.leadProvider === 'INFOBYTE_BROWNSENSE')?`WELCOME BROWNIES!`: `QUOTING MADE SIMPLE`) : ((!values.app_state.beenToSummary) ? "LET'S GET TO KNOW EACH OTHER" : "NEED TO CHANGE SOME DETAILS?")}</Typography>
                                    <Typography variant="h4" component="h4" className={"quizSubHeaderText"}
                                                display="block"
                                                gutterBottom
                                    >
                                        {((values.root_state.additionalInfo.leadProvider !== null) && (values.root_state.additionalInfo.leadProvider.length > 0) && (values.app_state.beenToSummary === false)) ? "We've pre-populated your information. Please review and step through to your quote." : ((!values.app_state.beenToSummary) ? "We’ll need some information about you to generate your personalised, no obligation, quote in four simple steps." : "Not a problem! You can update your personal details here, and click next to review your quote.")}

                                    </Typography></React.Fragment>) :
                                ((location.pathname.toString().toLowerCase() === "/quote/cover/options")) ?
                                    (<React.Fragment><Typography variant="h2" component="h2"
                                                                 className={"quizHeaderText"} display="block"
                                                                 gutterBottom
                                                                 align="center"> {values.root_state.mainMember.firstName},
                                        let's talk cover</Typography>
                                        <Typography variant="h4" component="h4" className={"quizSubHeaderText"}
                                                    display="block" gutterBottom
                                                    align="center">Please select whom you'd like to cover on this plan.

                                        </Typography></React.Fragment>) :
                                    ((location.pathname.toString().toLowerCase() === "/quote/about-you/cover-amount")) ?
                                        (<React.Fragment><Typography variant="h2" component="h2"
                                                                     className={"quizHeaderText"} display="block"
                                                                     gutterBottom
                                                                     align="center"> {values.root_state.mainMember.firstName},
                                            let's talk Funeral
                                            Cover</Typography>

                                            <Typography variant="h4" component="h4" className={"quizSubHeaderText"}
                                                        display="block"
                                                        gutterBottom align="center">
                                                How much would you need to cover your funeral needs?

                                            </Typography></React.Fragment>) :
                                        (location.pathname.toString().toLowerCase() === "/quote/add-life") ?
                                            (<React.Fragment><Typography variant="h2" component="h2"
                                                                         className={"quizHeaderText"} display="block"
                                                                         gutterBottom
                                                                         align="center">{values.root_state.mainMember.firstName},
                                                LET'S TALK
                                                FUNERAL PROTECTION </Typography>
                                                <Typography variant="h4" component="h4" className={"quizSubHeaderText"}
                                                            display="block"
                                                            gutterBottom align="center">
                                                    Would you like to add someone else to your plan?
                                                </Typography>
                                                <div style={{display: 'flex', paddingTop: 36}}>
                                                    <Typography variant="h4" component="h4"
                                                                className={'boldedSubheader'} gutterBottom align="left"
                                                                display="block">
                                                        You can cover up to 20 members in total
                                                    </Typography>
                                                    <IconButton aria-label="edit" color="primary"
                                                                onClick={event => toggleDrawer("")} size={"small"}
                                                                style={{position: 'relative', top: -4}}>
                                                        <InfoIcon fontSize={"inherit"}/>
                                                    </IconButton>
                                                </div>
                                            </React.Fragment>) :
                                            (location.pathname.toString().toLowerCase() === "/quote/about-them/detail") ?
                                                (<React.Fragment> <Typography variant="h2" component="h2"
                                                                              className={"quizHeaderText"}
                                                                              display="block"
                                                                              gutterBottom
                                                                              align="center">{(values.app_state.additionalLifeIndex !== -1) ? `TELL US MORE ABOUT YOUR ${lifeTypeList[UtilService.findByAttr(lifeTypeList, "lifeType", values.root_state.additionalLives[values.app_state.additionalLifeIndex].lifeType)].description.toUpperCase()}` : ""}</Typography>
                                                    <Typography variant="h4" component="h4"
                                                                className={"quizSubHeaderText"} display="block"
                                                                gutterBottom
                                                                align="center">
                                                        {"We’ll need a little more information about them in order to add them to your policy."}

                                                    </Typography></React.Fragment>) :
                                                (location.pathname.toString().toLowerCase() === "/quote/about-them/confirm-legal-child") ?
                                                    (<React.Fragment> <Typography variant="h2" component="h2"
                                                                                  className={"quizHeaderText"}
                                                                                  display="block"
                                                                                  gutterBottom
                                                                                  align="center">{(values.app_state.additionalLifeIndex !== -1) ? `TELL US MORE ABOUT ${(typeof values.root_state.additionalLives[values.app_state.additionalLifeIndex] !== "undefined") ? values.root_state.additionalLives[values.app_state.additionalLifeIndex].firstName.toUpperCase() : ""}` : ""}</Typography>

                                                        <Typography variant="h4" component="h4"
                                                                    className={"quizSubHeaderText"} display="block"
                                                                    gutterBottom
                                                                    align="center">
                                                            {"Are they your biological/legally adopted child?"}
                                                        </Typography></React.Fragment>) :
                                                    (location.pathname.toString().toLowerCase() === "/quote/about-them/child-checklist") ?
                                                        (<React.Fragment><Typography variant="h2" component="h2"
                                                                                     className={"quizHeaderText"}
                                                                                     display="block"
                                                                                     gutterBottom
                                                                                     align="center">{(values.app_state.additionalLifeIndex !== -1) ? `TELL US MORE ABOUT ${(typeof values.root_state.additionalLives[values.app_state.additionalLifeIndex] !== "undefined") ? values.root_state.additionalLives[values.app_state.additionalLifeIndex].firstName.toUpperCase() : ""}` : ""}</Typography>

                                                            <Typography variant="h4" component="h4"
                                                                        className={"quizSubHeaderText"} display="block"
                                                                        gutterBottom
                                                                        align="center">
                                                                {"We need to verify some points before we can cover them as a child on your plan"}
                                                            </Typography></React.Fragment>) :
                                                        (location.pathname.toString().toLowerCase() === "/quote/about-them/cover-amount") ?
                                                            (<React.Fragment><Typography variant="h2" component="h2"
                                                                                         className={"quizHeaderText"}
                                                                                         display="block"
                                                                                         gutterBottom
                                                                                         align="center">{(values.app_state.additionalLifeIndex !== -1) ? `TELL US MORE ABOUT ${(typeof values.root_state.additionalLives[values.app_state.additionalLifeIndex] !== "undefined") ? values.root_state.additionalLives[values.app_state.additionalLifeIndex].firstName.toUpperCase() : ""}` : ""}</Typography>

                                                                <Typography variant="h4" component="h4"
                                                                            className={"quizSubHeaderText"}
                                                                            display="block"
                                                                            gutterBottom align="center">
                                                                    How much would they need to cover their funeral
                                                                    needs?

                                                                </Typography></React.Fragment>) :
                                                            (location.pathname.toString().toLowerCase() === "/quote/benefits") ?
                                                                (<React.Fragment><Typography variant="h2" component="h2"
                                                                                             className={"quizHeaderText"}
                                                                                             display="block"
                                                                                             gutterBottom
                                                                                             align="center">{values.root_state.mainMember.firstName},
                                                                    LET'S TALK
                                                                    BENEFITS </Typography>
                                                                    <Typography variant="h4" component="h4"
                                                                                className={"quizSubHeaderText"}
                                                                                display="block"
                                                                                gutterBottom align="center">
                                                                        The Metropolitan Funeral product offers you a host of included
                                                                        benefits (<a target="_blank" rel="noreferrer"
                                                                                     href={API_CONFIG.SHOPFRONT_URL.concat("funeral/UGC_funeral_2020_10.pdf")}>read
                                                                        more</a>), as well as amazing
                                                                        optional ones you may choose to opt into.
                                                                        Select the benefits you would like to include.

                                                                    </Typography></React.Fragment>) :
                                                                (location.pathname.toString().toLowerCase() === "/quote/cart") ?
                                                                    (<React.Fragment><Typography variant="h2"
                                                                                                 component="h2"
                                                                                                 display="block"
                                                                                                 gutterBottom
                                                                                                 align="left"
                                                                                                 className={`quizHeaderText ${((!values.app_state.beenToSummary) && (values.app_state.currentStep === "/")) && (!mobileScreen) && 'noPadding'}  ${((values.app_state.beenToSummary === false) && ((values.app_state.currentStep === "/") || (values.app_state.currentStep === "/quote/cart") ) && ((values.root_state.additionalInfo.leadProvider !== null) && (values.root_state.additionalInfo.leadProvider.length > 0) && (values.root_state.additionalInfo.leadProvider === 'INFOBYTE_BROWNSENSE'))) && 'brownSenseHeader'}`}
                                                                    >
                                                                        {((values.root_state.additionalInfo.leadProvider !== null) && (values.root_state.additionalInfo.leadProvider.length > 0) && (values.app_state.beenToSummary === false) && (values.root_state.additionalInfo.leadProvider === 'INFOBYTE_BROWNSENSE'))?`WELCOME BROWNIES!`: `TIME
                                                                        TO GET
                                                                        EXCITED!`}</Typography>
                                                                        <Typography variant="h4" component="h4"
                                                                                    display="block" gutterBottom
                                                                                    align="left"
                                                                                    className={"quoteSummarySubHeaderText"}>
                                                                            Here’s your Metropolitan {values.app_state.fusion === false ? `Funeral`:`Funeral Fusion`} quote
                                                                        </Typography>
                                                                        <div className="quizSubHeaderText">
                                                                            <p>
                                                                                This is a quote based on your funeral
                                                                                needs.
                                                                                If you are not 100% satisfied with the
                                                                                monthly payment or you want to make
                                                                                changes to
                                                                                the cover
                                                                                amounts selected for yourself and your
                                                                                loved ones, please click on the Action
                                                                                buttons
                                                                                below to
                                                                                make the required changes. If you forgot
                                                                                to add a family member, click on the add
                                                                                life
                                                                                button
                                                                                below to add another family member and
                                                                                specify their details and the cover
                                                                                amount
                                                                                that you
                                                                                prefer.
                                                                            </p></div>
                                                                    </React.Fragment>) : (
                                                                        <React.Fragment></React.Fragment>)


                            }
                        </Grid>
                        <Grid item
                              hidden={(mobileScreen) || (location.pathname.toString().toLowerCase() === "/final/success")}>
                        </Grid>
                    </Grid>)}
                <Grid container
                      style={(location.pathname.toString().toLowerCase() !== "/final/success") ? {marginTop: '30px'} : {marginTop: 0}}
                      spacing={(location.pathname.toString().toLowerCase() === "/quote/about-you/cover-amount") ? 3 : 0}>
                    {/*<Slide direction="up" in={dialogActionStatusShow ? true : false} mountOnEnter unmountOnExit
                           className={"notificationAlert"}>
                        <Paper elevation={8}>
                            <Alert severity={(dialogActionStatus) ? "success" : "error"}>
                                {(dialogType === "callMe") ? ((dialogActionStatus) ? "Thank you for reaching out. One of our agents will call you shortly" : "Something went wrong. Please try again later") : ((dialogActionStatus) ? "Thank you! You'll get your special link shortly" : "Something went wrong. Please try again later")}
                            </Alert>
                        </Paper>
                    </Slide>*/}
                    <Snackbar open={dialogActionStatusShow} autoHideDuration={10000} onClose={handleCloseSnackBar}
                              anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                        <Alert onClose={handleCloseSnackBar}
                               severity={((typeof dialogActionStatus !== "undefined") && (dialogActionStatus !== null) && (dialogActionStatus.toString() === "true")) ? ((withinBusinessHours || dialogType === "saveForLater" ) ? "success" : "warning") : "error"}
                               sx={{width: '100%'}}>
                            {(dialogType === "callMe") ? ((dialogActionStatus) ? (withinBusinessHours ? "Thank you for reaching out. One of our agents will call you shortly" : "We have received your request, but you reached us outside of our business hours. We will call you during our business hours: Monday through Friday, 8 am – 4:30 pm.") : "Something went wrong. Please try again later") :
                                (dialogType === "WhatsApp") ? ((dialogActionStatus) ? (withinBusinessHours ? "Thank you for reaching out. One of our agents will WhatsApp you shortly" : "We have received your request, but you reached us outside of our business hours. We will WhatsApp you during our business hours: Monday through Friday, 8 am – 4:30 pm.") : "Something went wrong. Please try again later") :
                                    ((dialogActionStatus) ? "Thank you! You'll get your special link shortly" : "Something went wrong. Please try again later")}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12} sm={12} md={12}
                          lg={(location.pathname.toString().toLowerCase() !== "/final/success") ? 8 : 12} container
                          className={"minHeightContent"} spacing={(!mobileScreen) ? 3 : 0}>
                        <Slide direction="up" in={warningError ? true : false} mountOnEnter unmountOnExit
                               className={"notificationAlert"}>
                            <Paper elevation={4}>
                                <Alert severity="warning">
                                    {warningError}
                                </Alert>
                            </Paper>
                        </Slide>


                        {children}
                        {(((location.pathname.toString().toLowerCase() !== "/final/success")&& (location.pathname.toString().toLowerCase() !== "/underconstruction")) && (mobileScreen)) && (
                            <Grid item xs={12} sm={12} className="gridBottomPadding">

                                <hr className={"mainDivider fullWidth"}/>
                                <div align="right">
                                    <Button variant="outlined" endIcon={<SaveIcon/>}
                                            className={`productButton noOutlineButton  funeralSelfServiceButton mfpSaveForLater`}
                                            disabled={(loading || promiseInProgress) && true}
                                            onClick={() => handleDialogOpen("saveForLater")}>
                                        Save for later
                                    </Button>
                                </div>


                            </Grid>)}
                    </Grid>
                    {((location.pathname.toString().toLowerCase() !== "/final/success")&& (location.pathname.toString().toLowerCase() !== "/underconstruction")) && (
                        <Grid item>
                            {((values.app_state.currentStep.toString().toLowerCase() === "/") || (values.app_state.currentStep.toString().toLowerCase() === "/quote/cover/options"))

                                ?
                                (
                                    <div className={"sidePanelBlock"}>
                                        <div className={"disclaimerBlock"}>

                                    <div className={"disclaimerBlockHeaderSection"}>
                                        <Typography variant={"h4"} component={"h4"}
                                                    className={"disclaimerBlockHeaderText"}>
                                            NOT QUITE READY?
                                        </Typography>
                                    </div>
                                    <div className={"disclaimerBlockContent"}>
                                        <Typography className="disclaimerContent">
                                            If you'd like to <a className={"blurbLink"}
                                                                href={API_CONFIG.SHOPFRONT_URL.concat("product/funeral-cover/")}
                                                                target="_blank" rel="noopener noreferrer">learn
                                            more</a> about
                                            the product, have a look at our <a className={"blurbLink"}
                                                                               href={API_CONFIG.SHOPFRONT_URL.concat("product/funeral-cover/")}
                                                                               target="_blank"
                                                                               rel="noopener noreferrer">product
                                            page</a> to get more information.
                                        </Typography>


                                        <Button variant="contained" endIcon={<ArrowForwardIcon/>}
                                                className={`productButton disclaimerButton  funeralSelfServiceButton`}
                                                disabled={(loading || promiseInProgress) && true}
                                                onClick={getInfo}>
                                            Learn more
                                        </Button>
                                    </div>

                                            <hr className={"dialogDivider"}/>
                                            <div className={"disclaimerBlockContent"}>
                                                <Typography className="disclaimerContent">
                                                    Would you like to chat to a GetUp coach?
                                                </Typography>
                                                <Button variant="contained" endIcon={<PhoneEnabledIcon/>}
                                                        className={`productButton blueOutlineButton disclaimer funeralSelfServiceButton`}
                                                        disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                        onClick={() => handleDialogOpen("callMe")}>
                                                    Call me
                                                </Button>
                                                <Button variant="contained" endIcon={<Icon className={`endAdornmentIcon WA ${(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) && " disabledImageOption"}`}>
                                                    <img alt="marital status icon"
                                                         src={waIcon}/>
                                                </Icon>}
                                                        className={`productButton blueOutlineButton  funeralSelfServiceButton`}
                                                        disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                        onClick={() => handleDialogOpen("WhatsApp")}>
                                                    Whatsapp me
                                                </Button>
                                                {(UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) &&
                                                    <div className="helperText">
                                                        <Typography className="helperText">
                                                            You have already requested an agent to contact you today.
                                                        </Typography>
                                                        <Typography className="helperText">If you have any questions,
                                                            please either: </Typography>
                                                        <ul>
                                                            <li>chat with us using the chat icon in bottom-right corner
                                                                of the screen,
                                                            </li>
                                                            <li>send an email request to <a
                                                                href="mailto:info@metgetup.co.za">info@metgetup.co.za</a>,
                                                                or
                                                            </li>
                                                            <li><a
                                                                href="https://api.whatsapp.com/send/?phone=27110435300&text&app_absent=0"
                                                                target="_blank"
                                                                rel="noopener noreferrer">WhatsApp</a> us on +27 11 043
                                                                5300.
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>) :
                                (((wildcardMatch(values.app_state.currentStep.toString().toLowerCase(), "/about-them/*")) || (wildcardMatch(values.app_state.currentStep.toString().toLowerCase(), "/about-you/*"))) ? (
                                        <div className={"sidePanelBlock"}>
                                            <div className={"disclaimerBlock"}>

                                                <div className={"disclaimerBlockHeaderSection"}>
                                                    <Typography variant={"h4"} component={"h4"}
                                                                className={"disclaimerBlockHeaderText"}>
                                                        NOT QUITE READY?
                                                    </Typography>
                                                </div>

                                                <div className={"disclaimerBlockContent"}>
                                                    <Typography className="disclaimerContent">
                                                        Not quite sure about the benefits? Would you like to chat to a GetUp
                                                        coach?
                                                    </Typography>
                                                    <Button variant="contained" endIcon={<PhoneEnabledIcon/>}
                                                            className={`productButton blueOutlineButton disclaimer funeralSelfServiceButton mfpCallMe`}
                                                            disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                            onClick={() => handleDialogOpen("callMe")}>
                                                        Call me
                                                    </Button>
                                                    <Button variant="contained" endIcon={<Icon className={`endAdornmentIcon WA ${(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) && " disabledImageOption"}`}>
                                                        <img alt="marital status icon"
                                                             src={waIcon}/>
                                                    </Icon>}
                                                            className={`productButton blueOutlineButton  funeralSelfServiceButton mfpWhatsappMe`}
                                                            disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                            onClick={() => handleDialogOpen("WhatsApp")}>
                                                        Whatsapp me
                                                    </Button>
                                                    {(UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) &&
                                                        <div className="helperText">
                                                            <Typography className="helperText">
                                                                You have already requested an agent to contact you today.
                                                            </Typography>
                                                            <Typography className="helperText">If you have any questions,
                                                                please either: </Typography>
                                                            <ul>
                                                                <li>chat with us using the chat icon in bottom-right corner
                                                                    of the screen,
                                                                </li>
                                                                <li>send an email request to <a
                                                                    href="mailto:info@metgetup.co.za">info@metgetup.co.za</a>,
                                                                    or
                                                                </li>
                                                                <li><a
                                                                    href="https://api.whatsapp.com/send/?phone=27110435300&text&app_absent=0"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">WhatsApp</a> us on +27 11 043
                                                                    5300.
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>) :
                                    ((location.pathname.toString().toLowerCase() === "/quote/add-life") || (location.pathname.toString().toLowerCase() === "/quote/benefits") || (location.pathname.toString().toLowerCase() === "/quote/cart") || ((wildcardMatch(location.pathname.toString().toLowerCase(), "/buy/*")) && (location.pathname.toString().toLowerCase() !== "/buy/checkout"))) ?

                                        <React.Fragment>
                                            <div className={"sidePanelBlock"}>
                                                <div className={"quoteSummaryBlock"}>

                                                    <div className={"quoteSummaryBlockHeaderSection"}>
                                                        <Typography variant={"h4"} component={"h4"}
                                                                    className={"quoteSummaryBlockHeaderText"}>
                                                            {wildcardMatch(location.pathname.toString().toLowerCase(), "/buy/*") ? "Plan Summary" : "Quote Summary"}
                                                        </Typography>
                                                    </div>
                                                    <div className={"quoteSummaryBlockContent"}>
                                                        <div className={"quoteSummaryBlockSection"}>


                                                    <Typography className="quoteSummaryHeader">
                                                        Total Cover
                                                    </Typography>
                                                    <Typography className="quoteSummaryTotal">
                                                        {`R ${getTotalCoverAmount().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}
                                                    </Typography>
                                                    <Typography className="quoteSummarySubheader">
                                                        {(values.app_state.planType !== null) && (planTypeList[UtilService.findByAttr(planTypeList, "id", values.app_state.planType)].title)}
                                                    </Typography>
                                                </div>
                                                {(UtilService.getTotalNumberOfMembers(values) !== 0) ?
                                                <div className={"quoteSummaryBlockSection"}>
                                                    <Typography className="quoteSummaryHeader">
                                                        Total Premium
                                                    </Typography>
                                                    <Typography className="quoteSummaryTotal">
                                                        {renderStatus()} <span
                                                        style={{fontSize: 14, fontWeight: 'normal'}}>p/m</span>
                                                    </Typography>
                                                    <Typography className="quoteSummarySubheader">
                                                        {(values.root_state.valueProtectionBenefit.toString() === "true") && (
                                                            `Value protection benefit: ${(values.root_state.inflation)}%`
                                                        )}

                                                    </Typography>

                                                    <Typography className="quoteSummarySubheader">
                                                        {(values.root_state.cashBack.toString() === "true") && (
                                                            `Cashback benefit: R ${(values.root_state.cashbackPremium.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "))}`
                                                        )}

                                                    </Typography>
                                                </div> : <div className={"quoteSummaryBlockSection"}><Typography className="quoteSummaryHeader">
                                                        No members selected
                                                    </Typography>
                                                        <Grid item xs={12} style={{paddingTop: 21}}>
                                                        <Button variant="outlined"
                                                                endIcon={<GroupAddIcon/>}
                                                                className={`productButton blueOutlineButton mobile funeralSelfServiceButton`}
                                                                disabled={(loading || promiseInProgress) ? true : ((values.root_state.mainMember.coverAmount !== null) && ((values.root_state.mainMember.coverAmount > 0)))
                                                                    ? (
                                                                        ((values.app_state.GUPCount + values.app_state.GULPCount + values.app_state.GUCCount + values.app_state.GUCLCount + values.app_state.GUEFCount) >= 19)
                                                                    ) :
                                                                    (
                                                                        ((values.app_state.GUPCount + values.app_state.GULPCount + values.app_state.GUCCount + values.app_state.GUCLCount + values.app_state.GUEFCount) >= 20)
                                                                    )}
                                                                onClick={() => handleAddNewLife()}> ADD
                                                            A MEMBER
                                                        </Button></Grid></div>}
                                                {wildcardMatch(location.pathname.toString().toLowerCase(), "/quote/*") ?
                                                    <Button variant="contained" endIcon={<ArrowForwardIcon/>}
                                                            className={`productButton forwardButton disclaimer ${((location.pathname.toString().toLowerCase() === "/quote/cart") && (values.app_state.fusion === true))?" larger ":""}  funeralSelfServiceButton`}
                                                            disabled={(loading || promiseInProgress || values.root_state.totalPremiumForInsuredLives === 0) && true}
                                                            onClick={(location.pathname.toString().toLowerCase() === "/quote/cart") ? goToEnrol : handleToCart}>
                                                        {(location.pathname.toString().toLowerCase() === "/quote/cart") ? ( (values.app_state.fusion === true)? "Consolidate Now" : "Buy Now") : "Get Quote"}
                                                    </Button> : <Button variant="contained" startIcon={<ArrowBackIcon/>}
                                                                        style={{marginBottom: 12}}
                                                                        className={`productButton blueOutlineButton disclaimer funeralSelfServiceButton`}
                                                                        disabled={(loading || promiseInProgress) && true}
                                                                        onClick={handleToCart}>
                                                        {"Review Quote"}
                                                    </Button>}
                                                <Button variant="contained" endIcon={<SaveIcon/>}
                                                        disabled={(loading || promiseInProgress) && true}
                                                        className={`productButton blueOutlineButton  funeralSelfServiceButton mfpSaveForLater`}
                                                        onClick={() => handleDialogOpen("saveForLater")}>
                                                    Save for later
                                                </Button>
                                            </div>

                                                    <hr className={"dialogDivider quote"}/>
                                                    <div className={"disclaimerBlockContent"}>
                                                        <Typography className="disclaimerContent">
                                                            Would you like to chat to a GetUp coach?
                                                        </Typography>
                                                        <Button variant="contained" endIcon={<PhoneEnabledIcon/>}
                                                                name="callMe"
                                                                id="callMe"
                                                                className={`productButton blueOutlineButton disclaimer funeralSelfServiceButton mfpCallMe`}
                                                                disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                                onClick={() => handleDialogOpen("callMe")}
                                                        >
                                                            Call me
                                                        </Button>
                                                        <Button variant="contained" endIcon={<Icon className={`endAdornmentIcon WA ${(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) && " disabledImageOption"}`}>
                                                            <img alt="marital status icon"
                                                                 src={waIcon}/>
                                                        </Icon>}
                                                                className={`productButton blueOutlineButton  funeralSelfServiceButton mfpWhatsappMe`}
                                                                disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                                onClick={() => handleDialogOpen("WhatsApp")}>
                                                            Whatsapp me
                                                        </Button>
                                                        {(UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) &&
                                                            <div className="helperText">
                                                                <Typography className="helperText">
                                                                    You have already requested an agent to contact you today.
                                                                </Typography>
                                                                <Typography className="helperText">If you have any questions,
                                                                    please either: </Typography>
                                                                <ul>
                                                                    <li>chat with us using the chat icon in bottom-right corner
                                                                        of the screen,
                                                                    </li>
                                                                    <li>send an email request to <a
                                                                        href="mailto:info@metgetup.co.za">info@metgetup.co.za</a>,
                                                                        or
                                                                    </li>
                                                                    <li><a
                                                                        href="https://api.whatsapp.com/send/?phone=27110435300&text&app_absent=0"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">WhatsApp</a> us on +27 11 043
                                                                        5300.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>

                                                <hr className={"dialogDivider quote"}/>
                                                <div className={"disclaimerBlockContent"}>
                                                    <Typography className="disclaimerContentHeader">
                                                        RETURN POLICY – we have a 31-day cooling off period:
                                                    </Typography>
                                                    <Typography className="disclaimerContent">
                                                        Try us, if it doesn’t work for you, we will cancel your policy and return any money spent less any cost for cover you enjoyed.​
                                                    </Typography>


                                                </div>
                                                </div>
                                            </div>
                                            {(mobileScreen && (
                                                    <div className="fixed-bottom">
                                                        <div className="price-snackbar-Mobile">
                                                            <Grid container alignItems="center" direction="row"
                                                                  justifyContent="space-between"
                                                                  className="price-snackbar-MobileBackground">
                                                                <Grid item xs={12} className="priceTotalBoxMbile">
                                                                    {/*(typeof values.root_state.totalPremiumForInsuredLives !== "undefined") && (values.root_state.totalPremiumForInsuredLives !== 0)*/}
                                                                    {(getTotalNumberOfMembers() !== 0) ?
                                                                        <React.Fragment>
                                                                            {((error === null) || ((error !== null) && (error.length === 0))) && (
                                                                                <span
                                                                                    className="price-snackbar-MobileHeader">{wildcardMatch(location.pathname.toString().toLowerCase(), "/buy/*") ? "Your plan" : "Your quote"}</span>)}
                                                                            <span
                                                                                className="rand-val-mobile">{renderStatus()}</span>
                                                                            {((error === null) || ((error !== null) && (error.length === 0))) && (
                                                                                <span
                                                                                    className="per-month-Mobile"> p/m</span>)}
                                                                        </React.Fragment> :
                                                                        <span className="price-snackbar-MobileHeader">No Members</span>}
                                                                    <IconButton aria-label="edit"
                                                                                style={{color: '#FFFF'}}
                                                                                align={"right"}
                                                                                disabled={(loading || promiseInProgress || values.root_state.totalPremiumForInsuredLives === 0)}
                                                                                onClick={(location.pathname.toString().toLowerCase() === "/quote/cart") ? goToEnrol : handleToCart}>
                                                                        {wildcardMatch(location.pathname.toString().toLowerCase(), "/buy/*") ?
                                                                            <ArrowBackIcon/> : <ArrowForwardIcon/>}
                                                                    </IconButton>

                                                                </Grid>
                                                                <Grid item xs={12}>

                                        <span
                                            className="price-snackbar-MobileContent">Total cover: {`R ${getTotalCoverAmount().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`}</span>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                        <span
                                            className="price-snackbar-MobileContent">{UtilService.getTotalNumberOfMembers(values)} members</span>


                                                                </Grid>

                                                            </Grid>
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </React.Fragment> :
                                        ((location.pathname.toString().toLowerCase() === "/buy/checkout")) ?

                                            <div className={"sidePanelBlock"}>
                                                <div className={"paymentProtectBlock"}>


                                                    <div className={"quoteSummaryBlockContent"}>
                                                        <div >
                                                            <Grid container direction="row" spacing={mobileScreen ? 2 : 0}>
                                                            <Grid item xs={3} style={{textAlign: "left"}}>
                                                                <img src={secureIcon}/>
                                                            </Grid>
                                                            <Grid item xs={9} className=" ">
                                                                <Typography variant={"h4"} component={"h4"}
                                                                            className={"disclaimerBlockHeader"}>DON'T
                                                                    WORRY,
                                                                    YOUR
                                                                    DATA IS SAFE:</Typography>
                                                            </Grid>
                                                            </Grid>
                                                            <p>This is a secure 256-bit SSL encrypted site. This means
                                                                that the data you
                                                                enter is encrypted
                                                                when
                                                                it is sent to us and the encryption is so strong that it
                                                                is computationally
                                                                unfeasible to
                                                                crack
                                                                (look it up if you don't believe us.) </p>

                                                        </div>


                                                    </div>


                                                </div>
                                                <div className={"quoteSummaryBlock"}>

                                                    <div className={"quoteSummaryBlockHeaderSection"}>
                                                        <Typography variant={"h4"} component={"h4"}
                                                                    className={"quoteSummaryBlockHeaderText"}>
                                                            HOW IT WORKS
                                                        </Typography>
                                                    </div>
                                                    <div className={"quoteSummaryBlockContent"}>
                                                        {values.root_state.paymentMethod === "Debit Order" ? (
                                                        <div>

                                                            <Typography variant={"h4"} component={"h4"}
                                                                        className={"quoteSummaryContentHeading"}>
                                                                DEBIT ORDER</Typography>
                                                            <p className="noteContent">Please note that as the plan
                                                                owner, the bank account
                                                                needs to be in your name. The payment details provided
                                                                will be validated
                                                                against your personal information. </p>
                                                            <p className="noteContent">If you have any questions, please
                                                                either: </p>
                                                            <ul className="noteContent" style={{
                                                                paddingInlineStart: 20
                                                            }}>
                                                                <li>chat with us using the chat icon in bottom-right
                                                                    corner of the screen,
                                                                </li>
                                                                <li>send an email request to <a
                                                                    href="mailto:info@metgetup.co.za">info@metgetup.co.za</a>,
                                                                    or
                                                                </li>
                                                                <li><a
                                                                    href="https://api.whatsapp.com/send/?phone=27110435300&text&app_absent=0"
                                                                    target="_blank"
                                                                    rel="noopener noreferrer">WhatsApp us on +27 11 043 5300.</a>
                                                                </li>
                                                            </ul>


                                                        </div>) : (
                                                            <div>
                                                            <Typography variant={"h4"} component={"h4"}
                                                                        className={"quoteSummaryContentHeading"}>
                                                                {flexiblePaymentOptionsList[UtilService.findByAttr(flexiblePaymentOptionsList, "value", values.app_state.flexiblePaymentOption)].sidePanel.title}</Typography>

                                                                {flexiblePaymentOptionsList[UtilService.findByAttr(flexiblePaymentOptionsList, "value", values.app_state.flexiblePaymentOption)].sidePanel.content.map((item, index) => (
                                                                    <p className="noteContent" key={index}> {item.section}</p>

                                                                    ))}
                                                                <Typography variant={"h5"} component={"h5"}
                                                                            className={"quoteSummaryContentSubHeading"}>Explainer video</Typography>
                                                                <div className="iframe-container">
                                                                    <iframe width="100%" src={flexiblePaymentOptionsList[UtilService.findByAttr(flexiblePaymentOptionsList, "value", values.app_state.flexiblePaymentOption)].sidePanel.videoLink}
                                                                            title={flexiblePaymentOptionsList[UtilService.findByAttr(flexiblePaymentOptionsList, "value", values.app_state.flexiblePaymentOption)].sidePanel.title}
                                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                                            allowFullScreen></iframe>
                                                                </div>
                                                            </div>
                                                        )}


                                                    </div>


                                                </div>
                                            </div>
                                            :

                                            ((location.pathname.toString().toLowerCase() !== "/underconstruction") ?
                                            (<div className={"sidePanelBlock"}>
                                                <div className={"disclaimerBlock"}>
                                                    <div className={"disclaimerBlockHeaderSection"}>
                                                        <Typography variant={"h4"} component={"h4"}
                                                                    className={"disclaimerBlockHeaderText"}>
                                                            NEED HELP?
                                                        </Typography>
                                                    </div>
                                                    <div className={"disclaimerBlockContent"}>
                                                        <Typography className="disclaimerContentHeader">
                                                            NOT SURE HOW MUCH A FUNERAL WILL COST?
                                                        </Typography>
                                                        <Typography className="disclaimerContent">
                                                            Complete our Funeral Planner to make sure your cover matches
                                                            your
                                                            funeral needs.
                                                        </Typography>
                                                        <Button variant="contained" endIcon={<ArrowForwardIcon/>}
                                                                className={`productButton disclaimerButton  funeralSelfServiceButton`}
                                                                onClick={() => goToPlanner()}>
                                                            Funeral Planner
                                                        </Button>

                                                    </div>
                                                    <hr className={"dialogDivider"}/>
                                                    <div className={"disclaimerBlockContent"}>
                                                        <Typography className="disclaimerContent">
                                                            Would you like to chat to a GetUp coach?
                                                        </Typography>
                                                        <Button variant="contained" endIcon={<PhoneEnabledIcon/>}
                                                                className={`productButton blueOutlineButton disclaimer funeralSelfServiceButton mfpCallMe`}
                                                                disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                                onClick={() => handleDialogOpen("callMe")}>
                                                            Call me
                                                        </Button>
                                                        <Button variant="contained" endIcon={<Icon className={`endAdornmentIcon WA ${(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) && " disabledImageOption"}`}>
                                                            <img alt="marital status icon"

                                                                 src={waIcon}/>
                                                        </Icon>}
                                                                className={`productButton blueOutlineButton  funeralSelfServiceButton mfpWhatsappMe`}
                                                                disabled={(loading || promiseInProgress) || (UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested))}
                                                                onClick={() => handleDialogOpen("WhatsApp")}>
                                                            Whatsapp me
                                                        </Button>
                                                        {(UtilService.isWithin24Hours(values.root_state.webChatTicketDateRequested)) &&
                                                            <div className="helperText">
                                                                <Typography className="helperText">
                                                                    You have already requested an agent to contact you today.
                                                                </Typography>
                                                                <Typography className="helperText">If you have any questions,
                                                                    please either: </Typography>
                                                                <ul>
                                                                    <li>chat with us using the chat icon in bottom-right corner
                                                                        of the screen,
                                                                    </li>
                                                                    <li>send an email request to <a
                                                                        href="mailto:info@metgetup.co.za">info@metgetup.co.za</a>,
                                                                        or
                                                                    </li>
                                                                    <li><a
                                                                        href="https://api.whatsapp.com/send/?phone=27110435300&text&app_absent=0"
                                                                        target="_blank"
                                                                        rel="noopener noreferrer">WhatsApp</a> us on +27 11 043
                                                                        5300.
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>):<React.Fragment></React.Fragment>))
                            }
                        </Grid>)}
                </Grid>


            </Container>
            <Drawer
                anchor="right"
                variant="persistent"
                className={"helpDrawer"}
                open={openDrawer}
                onClose={event => toggleDrawer("")}


            >
                <HelpDrawer
                    clickedItem={""}
                    dialogContent={""
                    }
                    title={"Additional Lives"}

                    toggleDrawerClose={event => toggleDrawer("")}/>

            </Drawer>
            <Dialog open={openDialog} onClose={handleDialogClose} className={"dialogStyle"}>
                <SidePanel parentCallbackClose={handleDialogClose}
                           dialogType={dialogType}/>
            </Dialog>

        </React.Fragment>
    )
}

SidePanelLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default SidePanelLayout
