import moment from "moment";

class UtilService {
    getAgeFromDoB = (dob) => {
        return moment().diff(moment(dob, 'YYYY-MM-DD'), 'years');
    }
    capitiliseEachWord = (inputData) => {
        return inputData.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }
    isWithin24Hours = (inputDate) => {
       let startTime = moment(inputDate);
       //console.log(JSON.stringify(startTime))
       if (startTime.isValid()){
           let duration = moment.duration(moment().diff(startTime));
           let hours = duration.asHours();

           return hours <= 24;
       }
       else
       {
           return false;
       }
    }

    isWithinWorkingHours = () => {
        let now = moment();
        return now.day() <= 5 && now.hour() >= 9 && now.hour() < 17;
    }
    findByAttr = (array, attr, value) => {
        for(let i = 0; i < array.length; i += 1) {

            if(array[i][attr].toString().valueOf() === value.toString().valueOf()) {
                return i;
            }
        }
        return -1;
    };
    findByAttrThirdParty = (array,providerName, attr, value) => {
        for(let i = 0; i < array.length; i += 1) {
            if (providerName.toUpperCase() === "HIPPO"){
                if(array[i].activeForHippo === true) {
                    if (array[i][attr] === value) {
                        return i;
                    }
                }
            }

        }
        return -1;
    }
    findByAttrALRel = (array, attr, value) => {
        for(let i = 0; i < array.length; i += 1) {
            if(array[i].active_al === true) {
                if (array[i][attr] === value) {
                    return i;
                }
            }
        }
        return -1;
    }

     findByAttrBRel = (array, attr, value) => {
        for(let i = 0; i < array.length; i += 1) {
            if(array[i].active_beneficiary === true) {
                if (array[i][attr] === value) {
                    return i;
                }
            }
        }
        return -1;
    }

     getFirstDayOfNextMonth = () => {
        return moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    }

    getTotalNumberOfMembers = (values) => {
        let total = 0;
        if ((typeof values.root_state.mainMember.coverAmount !== "undefined") && (values.root_state.mainMember.coverAmount !== null) && (values.root_state.mainMember.coverAmount > 0)) {
            total += 1;
        }
        if ((typeof values.root_state.additionalLives !== "undefined") && (values.root_state.additionalLives.length > 0)) {
            let childCounted = false;
            values.root_state.additionalLives.forEach((item, index) => {
                if ((typeof item.coverAmount !== "undefined") && (item.coverAmount !== null) && (item.coverAmount > 0) ) {
                    if (((item.lifeType === "GUC") && (childCounted === false)) || (item.lifeType !== "GUC")) {
                        total += 1;
                    }

                    if ((item.lifeType === "GUC") && (childCounted === false)){
                        childCounted = true;
                    }
                }
            })
        }
        return total
    }

    getTotalCoverOfMembers = (values) => {
        let total = 0;
        if ((typeof values.root_state.mainMember.coverAmount !== "undefined") && (values.root_state.mainMember.coverAmount !== null) && (values.root_state.mainMember.coverAmount > 0)) {
            total = total + values.root_state.mainMember.coverAmount;
        }
        if ((typeof values.root_state.additionalLives !== "undefined") && (values.root_state.additionalLives.length > 0)) {
            values.root_state.additionalLives.forEach((item, index) => {
                if ((typeof item.coverAmount !== "undefined") && (item.coverAmount !== null) && (item.coverAmount > 0) ) {
                    total = total + item.coverAmount
                }
            })
        }
        console.log("FinalTotal: " + total)
        return total
    }

}

export default new UtilService();