import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';


import {useFormContext} from "../../components/customHooks/useFormContext";


import TextField from "@material-ui/core/TextField";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SaveIcon from "@material-ui/icons/Save";
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import QuoteService from "../../services/APIService";
import UtilService from "../../services/UtilService";
import Loader from "react-loader-spinner";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import InputAdornment from "@material-ui/core/InputAdornment";
import PermDeviceInformationIcon from "@material-ui/icons/PermDeviceInformation";
import DialogActions from "@material-ui/core/DialogActions";
import {mainMemberDialogValidation} from "../../validations/quote/mainMemberDialogValidation";
import Dialog from "@material-ui/core/Dialog";
import OtpInput from 'react-otp-input';
import {Grid} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: 500,
        height: 500,

    },
    cardContent: {
        height: 100,
        paddding: 0
    },
    cardMedia: {
        /*paddingTop: 150, // 16:9
        width: 150,
        border: "1px solid #9900cc !important",
        borderRadius: "150px",
        cursor: "pointer"*/
        paddingTop: 100, // 16:9
        width: 100,
    },
    gridItem: {
        width: 180,
        height: 180,
        borderRadius: 90
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));


const SidePanel = (props) => {
    const [typeButton, setTypeButton] = React.useState();
    const [activeForm, setActiveForm] = React.useState(1);
    const [success, setSuccess] = React.useState(true);
    const [otpError, setOTPError] = React.useState(false);
    const [otpErrorType, setOTPErrorType] = React.useState("");
    const [loading, setLoading] = useState(false);
    const {promiseInProgress} = usePromiseTracker();


    const resendOTP = async () => {


        setOTPError(false)
        await trackPromise(QuoteService.CreateOTP(values)
            .then(r => {
                setSuccess(true)
                setOTPErrorType("")
            }).catch(error => {
                setSuccess(false)
                setOTPError(true)
            }).finally(() => {
                let tempValues = _.cloneDeep(values);
                tempValues.app_state.enteredOTP = "";
                setValues(tempValues)
            }))
    }

    const handleVerifyOTP = async () => {
        let temp_Values = _.cloneDeep(values);
        let step = null;
        if ((temp_Values.app_state.enteredOTP !== null) && (temp_Values.app_state.enteredOTP.length !== 0)) {

            await trackPromise(QuoteService.VerifyOTP(values)
                .then(r => {
                    if (r.data.toString() === "false") {
                        setOTPError(true)
                        setOTPErrorType("ValidationFailed")

                    } else {
                        setOTPError(false)
                        if (UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) !== -1) {
                            temp_Values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone)].validated = true;
                        }
                        if (props.dialogType === "callMe") {
                            return QuoteService.CreateZendeskChat(values.app_state.SESSION_ID, props.dialogType)
                                .then(r => {
                                    if ((typeof r !== "undefined") && (typeof r.data !== "undefined") && (r.data !== null)) {

                                        temp_Values.root_state.webChatTicket = r.data
                                        temp_Values.root_state.webChatTicketDateRequested = moment();
                                    }
                                    setSuccess(true);
                                })
                                .catch(error => {
                                    setSuccess(false);
                                }).finally(() => {
                                    /*props.parentCallbackClose(success);*/
                                    props.parentCallbackClose(success, temp_Values, ((props.dialogType === "validateMe") ? "buyNow" : null));
                                })
                        } else if (props.dialogType === "WhatsApp") {
                            return QuoteService.CreateZendeskChat(values.app_state.SESSION_ID, props.dialogType)
                                .then(r => {
                                    if ((typeof r !== "undefined") && (typeof r.data !== "undefined") && (r.data !== null)) {

                                        temp_Values.root_state.webChatTicket = r.data
                                        temp_Values.root_state.webChatTicketDateRequested = moment();

                                    }
                                    setSuccess(true);
                                })
                                .catch(error => {
                                    setSuccess(false);
                                }).finally(() => {
                                    /* props.parentCallbackClose(success);*/
                                    props.parentCallbackClose(success, temp_Values, null);
                                })


                        } else if (props.dialogType === "validateMe") {
                            /*setValues(temp_Values);
                            props.parentCallbackClose(success, "buyNow");*/
                            props.parentCallbackClose(success, temp_Values, ((props.dialogType === "validateMe") ? "buyNow" : null));

                        } else if (props.dialogType === "numberChangedEnrol") {
                            /*setValues(temp_Values);
                            props.parentCallbackClose(success, "buyNow");*/
                            if (temp_Values.app_state.currentStep === 'buy/step-2') {
                                props.parentCallbackClose(success, temp_Values, "/buy/step-3");
                            }

                        } else if (props.dialogType === "saveForLater") {
                            return QuoteService.SaveQuote(values.app_state.SESSION_ID)
                                .then(r => {
                                    //console.log(JSON.stringify(r))
                                    setSuccess(true);

                                })
                                .catch(error => {
                                    setSuccess(false);
                                }).finally(() => {
                                    /*props.parentCallbackClose(success);*/
                                    props.parentCallbackClose(success, temp_Values, null);
                                })
                        }




                    }
                }).catch(error => {
                    setSuccess(false)

                })).finally(() => {
                // setValues(temp_Values);

            })
        } else {
            setOTPError(true)
            setOTPErrorType("Empty")
        }
    }

    const handleDialogAction = () => {
        let temp_Values = _.cloneDeep(values);
        if (activeForm === 1) {
            //console.log(JSON.stringify(temp_Values.root_state.mainMember ))
            if (temp_Values.root_state.mainMember.knownConsumerNumber === null) {
                trackPromise(QuoteService.CreateKnownConsumer(values)
                    .then(r => {
                        temp_Values.root_state.mainMember.knownConsumerNumber = r.data;
                        if (props.dialogType === "WhatsApp") {

                            temp_Values.root_state.whatsappConsent = true;
                            QuoteService.GetKnownConsumerConsent(temp_Values).then(r => {
                                if (r.data.length === 0) {
                                    QuoteService.CreateKnownConsumerConsent("Contact via Whatsapp", temp_Values).then(r => {
                                        trackPromise(QuoteService.CreateOTP(temp_Values)
                                            .then(r => {

                                                setValues(temp_Values);
                                                setActiveForm(2);
                                            }).catch(error => {
                                                setSuccess(false)
                                                setError("Something went wrong. Please try again.")
                                            }));
                                    }).catch(error => {
                                        setSuccess(false);
                                    })
                                } else {
                                    if (UtilService.findByAttr(r.data, "consentType", "Contact via Whatsapp") !== -1) {
                                        QuoteService.UpdateKnownConsumerConsent("Contact via Whatsapp", temp_Values).then(r => {
                                            trackPromise(QuoteService.CreateOTP(temp_Values)
                                                .then(r => {

                                                    setValues(temp_Values);
                                                    setActiveForm(2);
                                                }).catch(error => {
                                                    setSuccess(false)
                                                    setError("Something went wrong. Please try again.")
                                                }));
                                        }).catch(error => {
                                            setSuccess(false);
                                        })
                                    } else {
                                        QuoteService.CreateKnownConsumerConsent("Contact via Whatsapp", temp_Values).then(r => {
                                            trackPromise(QuoteService.CreateOTP(temp_Values)
                                                .then(r => {

                                                    setValues(temp_Values);
                                                    setActiveForm(2);
                                                }).catch(error => {
                                                    setSuccess(false)
                                                    setError("Something went wrong. Please try again.")
                                                }));
                                        }).catch(error => {
                                            setSuccess(false);
                                        })
                                    }
                                }
                            }).catch(error => {
                                setSuccess(false);
                            })

                        } else {
                            trackPromise(QuoteService.CreateOTP(temp_Values)
                                .then(r => {

                                    setValues(temp_Values);
                                    setActiveForm(2);
                                }).catch(error => {
                                    setSuccess(false)
                                    setError("Something went wrong. Please try again.")
                                }));
                        }
                    }).catch(error => {
                        setSuccess(false)
                        setError("Something went wrong. Please try again.")
                    })).finally(() => {
                    temp_Values.app_state.enteredOTP = "";
                    setValues(temp_Values);
                });
            } else {
                if (UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) !== -1) {
                    //console.log(JSON.stringify(values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone)].validated))
                    if (values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone)].validated === true) {
                        if (props.dialogType === "callMe") {
                            trackPromise(QuoteService.CreateZendeskChat(values.app_state.SESSION_ID, props.dialogType)
                                .then(r => {
                                    if ((typeof r !== "undefined") && (typeof r.data !== "undefined") && (r.data !== null)) {
                                        let temp_Values = _.cloneDeep(values);
                                        temp_Values.root_state.webChatTicket = r.data
                                        temp_Values.root_state.webChatTicketDateRequested = moment();
                                        setValues(temp_Values);
                                    }
                                    setSuccess(true)
                                })
                                .catch(error => {
                                    setSuccess(false)
                                    setError("Something went wrong. Please try again.")
                                }).finally(() => {
                                    props.parentCallbackClose(success);
                                }));
                        } else if (props.dialogType === "WhatsApp") {
                            let temp_Values = _.cloneDeep(values);
                            temp_Values.root_state.whatsappConsent = true;
                            QuoteService.GetKnownConsumerConsent(temp_Values).then(r => {
                                //console.log(JSON.stringify(r))
                                //console.log(r.data.length)
                                if (r.data.length === 0) {

                                    QuoteService.CreateKnownConsumerConsent("Contact via Whatsapp", temp_Values).then(r => {
                                        return trackPromise(QuoteService.CreateZendeskChat(values.app_state.SESSION_ID, props.dialogType)
                                            .then(r => {
                                                if ((typeof r !== "undefined") && (typeof r.data !== "undefined") && (r.data !== null)) {
                                                    let temp_Values = _.cloneDeep(values);
                                                    temp_Values.root_state.webChatTicket = r.data;
                                                    temp_Values.root_state.webChatTicketDateRequested = moment();
                                                    setValues(temp_Values);
                                                }
                                                setSuccess(true)
                                            })
                                            .catch(error => {
                                                setSuccess(false)
                                                setError("Something went wrong. Please try again.")
                                            }).finally(() => {
                                                props.parentCallbackClose(success);
                                            }));
                                    }).catch()
                                } else {
                                    if (UtilService.findByAttr(r.data, "consentType", "Contact via Whatsapp") !== -1) {
                                        if (r.data[UtilService.findByAttr(r.data, "consentType", "Contact via Whatsapp")] !== true) {
                                            QuoteService.UpdateKnownConsumerConsent("Contact via Whatsapp", temp_Values).then(r => {
                                                return trackPromise(QuoteService.CreateZendeskChat(values.app_state.SESSION_ID, props.dialogType)
                                                    .then(r => {
                                                        if ((typeof r !== "undefined") && (typeof r.data !== "undefined") && (r.data !== null)) {
                                                            let temp_Values = _.cloneDeep(values);
                                                            temp_Values.root_state.webChatTicket = r.data;
                                                            temp_Values.root_state.webChatTicketDateRequested = moment();
                                                            setValues(temp_Values);
                                                        }
                                                        setSuccess(true)
                                                    })
                                                    .catch(error => {
                                                        setSuccess(false)
                                                        setError("Something went wrong. Please try again.")
                                                    }).finally(() => {
                                                        props.parentCallbackClose(success);
                                                    }));
                                            }).catch()
                                        }
                                    } else {
                                        QuoteService.CreateKnownConsumerConsent("Contact via Whatsapp", temp_Values).then(r => {
                                            return trackPromise(QuoteService.CreateZendeskChat(values.app_state.SESSION_ID, props.dialogType)
                                                .then(r => {
                                                    if ((typeof r !== "undefined") && (typeof r.data !== "undefined") && (r.data !== null)) {
                                                        let temp_Values = _.cloneDeep(values);
                                                        temp_Values.root_state.webChatTicket = r.data;
                                                        temp_Values.root_state.webChatTicketDateRequested = moment();
                                                        setValues(temp_Values);
                                                    }
                                                    setSuccess(true)
                                                })
                                                .catch(error => {
                                                    setSuccess(false)
                                                    setError("Something went wrong. Please try again.")
                                                }).finally(() => {
                                                    props.parentCallbackClose(success);
                                                }));
                                        }).catch()
                                    }
                                }
                            }).catch()


                        } else if (props.dialogType === "saveForLater") {
                            return trackPromise(QuoteService.SaveQuote(values.app_state.SESSION_ID)
                                .then(r => {
                                    setSuccess(true)
                                })
                                .catch(error => {
                                    setSuccess(false)
                                    setError("Something went wrong. Please try again.")
                                }).finally(() => {
                                    props.parentCallbackClose(success);
                                }));
                        }
                        else {

                        }
                    } else {
                        //console.log("item exsits. Not validated")
                        trackPromise(
                            QuoteService.CreateOTP(temp_Values)
                                .then(r => {
                                    temp_Values.app_state.enteredOTP = "";
                                    setValues(temp_Values);
                                    setActiveForm(2);
                                    setSuccess(true)
                                }).catch(error => {
                                setSuccess(false)
                                setError("Something went wrong. Please try again.")
                            })).finally(() => {

                        })
                    }
                } else {
                    trackPromise(
                        trackPromise(QuoteService.UpdateKnownConsumer(values)
                            .then(r => {
                                //console.log(JSON.stringify(r))
                                QuoteService.CreateOTP(temp_Values)
                                    .then(r => {
                                        temp_Values.app_state.enteredOTP = "";
                                        setValues(temp_Values);
                                        setActiveForm(2);
                                        setSuccess(true)
                                    }).catch(error => {
                                    setSuccess(false)
                                    setError("Something went wrong. Please try again.")
                                })
                            })).catch(error => {
                            setSuccess(false)
                            setError("Something went wrong. Please try again.")
                        }))
                }
            }
        } else {


        }

    };
    const handleCancel = () => {
        props.parentCallbackClose(null)
    }


    const [
        values,
        errors,
        resetState,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit
    ] = useFormContext(handleDialogAction, mainMemberDialogValidation);
    const currentStateRef = useRef();
    const prevTotalPremiumRef = useRef(null);
    const prevTotalPremium = prevTotalPremiumRef.current;
    const [errorsCopy, setErrorsCopy] = useState(mainMemberDialogValidation(values, "mainMember", null, null, _.cloneDeep(errors), false));
    const [error, setError] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const LoadingIndicator = props => {
        const {promiseInProgress} = usePromiseTracker();
        return (
            promiseInProgress && (
                <div className="loaderClass">
                    <Loader type="ThreeDots" color="#3c1f41" height="50" width="50"/>
                </div>
            )
        );
    };


    const theme = useTheme();
    const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const classes = useStyles();

    const handleNumericChange = value => {
        //console.log(value)
        let re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            let temp_Value = _.cloneDeep(values);
            temp_Value.app_state.enteredOTP = value;
            setValues(temp_Value)
        }
    }

    const handleTelephoneNumericChange = event => {
        let re = /^[+-]?\d*\.?\d+(?:[Ee][+-]?\d+)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            handleChange(event, "mainMember");
        }
    }
    const handleTelephoneNumericBlur = event => {
        let re = /^[+-]?\d*\.?\d+(?:[Ee][+-]?\d+)?$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            handleBlur(event, "mainMember")
        }
    }


    return (
        <React.Fragment>
            <Snackbar open={!success} autoHideDuration={6000}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
                <Alert severity={"error"}
                       sx={{width: '100%'}}>
                    {error}
                </Alert>
            </Snackbar>
            {(activeForm === 1) ? (
                <div>
                    <DialogTitle
                        className={"customDialogTitle"}>{(props.dialogType === "callMe") ? "Call me" : (props.dialogType === "WhatsApp") ? "WhatsApp Me" : (props.dialogType === "validateMe") ? "Just in case" : "Save for Later"}</DialogTitle>
                    <DialogContent className={"dialogContainer"}>
                        {((UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone) !== -1) && (values.root_state.mainMember.contactDetails[UtilService.findByAttr(values.root_state.mainMember.contactDetails, "contactValue", values.root_state.mainMember.cellphone)].validated === true)) ?
                            <DialogContentText className={"customDialogContentText"}>
                                Please confirm the number you wish to be contacted on.
                            </DialogContentText>
                            :
                            <React.Fragment>
                                <DialogContentText className={"customDialogSubTitle"}>
                                    We just want to make sure it’s you.
                                </DialogContentText>
                                <DialogContentText className={"customDialogContentText"}>
                                    {(props.dialogType === "callMe") ? "We want to make sure we have your correct number, please confirm your number and press submit to receive a confirmation OTP on this number." : (props.dialogType === "WhatsApp") ? "We want to make sure we have your correct number, please confirm your number and press submit to receive a confirmation OTP on this number." : (props.dialogType === "validateMe") ? "Before we continue, we need to verify your identity. We will be sending you an OTP to the below number. Please confirm that it is correct before continuing." : "We want to make sure we have your correct number, please confirm your number and press submit to receive a confirmation OTP on this number. Once confirmed, we'll send you a link to pick up where you left off"}

                                </DialogContentText>
                            </React.Fragment>
                        }
                        {!(((typeof errorsCopy.mainMember === "undefined") || ((typeof errorsCopy.mainMember !== "undefined") && ((typeof errorsCopy.mainMember.firstName === "undefined") && (typeof errorsCopy.mainMember.lastName === "undefined"))))) ? (
                            <React.Fragment>
                                <Grid item xs={12} className="gridBottomPadding">
                                    <InputLabel
                                        required
                                        error={(((typeof errors.mainMember !== "undefined")) && (errors.mainMember.firstName)) ? true : false}
                                        className={"inputLabelClass"}>First Name</InputLabel>

                                    <TextField
                                        id="firstName"
                                        name="firstName"
                                        type="text"
                                        required
                                        fullWidth
                                        placeholder={`Enter your name`}
                                        error={(((typeof errors.mainMember !== "undefined")) && (errors.mainMember.firstName)) ? true : false}
                                        disabled={(loading || promiseInProgress) && true}
                                        value={values.root_state.mainMember.firstName}
                                        onBlur={event => handleBlur(event, "mainMember")}
                                        onChange={event => handleChange(event, "mainMember")}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon/>
                                                </InputAdornment>
                                            ),

                                        }}
                                        inputProps={{
                                            tabIndex: 1
                                        }}
                                        helperText={(((typeof errors.mainMember !== "undefined")) && (errors.mainMember.firstName)) ? errors.mainMember.firstName : ""}
                                        variant="outlined"
                                    />
                                    {/*   </FormControl>*/}
                                </Grid>

                                <Grid item xs={12} className="gridBottomPadding">
                                    <InputLabel
                                        required
                                        error={(((typeof errors.mainMember !== "undefined")) && (errors.mainMember.lastName)) ? true : false}
                                        className={"inputLabelClass"}>Surname</InputLabel>

                                    <TextField
                                        id="lastName"
                                        name="lastName"
                                        type="text"
                                        required
                                        fullWidth
                                        placeholder={`Enter your surname`}
                                        error={(((typeof errors.mainMember !== "undefined")) && (errors.mainMember.lastName)) ? true : false}
                                        disabled={(loading || promiseInProgress) && true}
                                        value={values.root_state.mainMember.lastName}
                                        onBlur={event => handleBlur(event, "mainMember")}
                                        onChange={event => handleChange(event, "mainMember")}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon/>
                                                </InputAdornment>
                                            ),
                                        }}
                                        inputProps={{
                                            tabIndex: 2
                                        }}
                                        helperText={(((typeof errors.mainMember !== "undefined")) && (errors.mainMember.lastName)) ? errors.mainMember.lastName : ""}
                                        variant="outlined"
                                    />
                                </Grid></React.Fragment>) : (<React.Fragment></React.Fragment>)}
                        <Grid item xs={12} className="gridBottomPadding">
                            <InputLabel
                                required
                                error={(((typeof errors.mainMember !== "undefined")) && ((errors.mainMember.cellphone) || (errors.mainMember.contactDetails))) ? true : false}
                                className={"inputLabelClass"}>Cellphone number</InputLabel>

                            <TextField
                                id="cellphone"
                                name="cellphone"
                                type="text"
                                required
                                fullWidth
                                placeholder={`Enter your cellphone number, e.g. 0866601603`}
                                error={(((typeof errors.mainMember !== "undefined")) && ((errors.mainMember.cellphone) || (errors.mainMember.contactDetails))) ? true : false}
                                disabled={(loading || promiseInProgress) && true}
                                value={values.root_state.mainMember.cellphone}
                                onBlur={handleTelephoneNumericBlur}
                                onChange={handleTelephoneNumericChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <PermDeviceInformationIcon/>
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    tabIndex: 3
                                }}
                                helperText={(((typeof errors.mainMember !== "undefined")) && (errors.mainMember.cellphone)) ? errors.mainMember.cellphone : ""}
                                variant="outlined"
                            />
                        </Grid>
                    </DialogContent>
                    <DialogActions className={"dialogContainer"}>
                        <LoadingIndicator/>
                        <Button variant="contained"
                                className={`productButton backButton  funeralSelfServiceButton`}
                                disabled={(loading || promiseInProgress) && true}
                                onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" className={`productButton forwardButton  funeralSelfServiceButton`}
                                disabled={(loading || promiseInProgress) && true}
                                onClick={handleSubmit}>Submit</Button>
                    </DialogActions>
                </div>) : (
                <div>

                    <DialogTitle className={"customDialogTitle"}>Verify number</DialogTitle>
                    <DialogContent className={"dialogContainer"}>
                        <DialogContentText className={"customDialogSubTitle"}>
                            OTP sent to the cellphone number:
                        </DialogContentText>
                        <DialogContentText className={"customDialogContentText emphasisText"}>
                            {values.root_state.mainMember.cellphone}
                            <Button variant="outlined"
                                    className={`productButton noOutlineButton  funeralSelfServiceButton`}
                                    disabled={(loading || promiseInProgress) && true}
                                    onClick={() => setActiveForm(1)}>
                                CHANGE NUMBER
                            </Button>
                        </DialogContentText>

                        <InputLabel
                            required
                            error={otpError}
                            className={"inputLabelClass"}>OTP</InputLabel>

                        <OtpInput
                            value={values.app_state.enteredOTP}
                            hasErrored={otpError}
                            onChange={handleNumericChange}
                            numInputs={4}
                            isInputNum={true}
                            className={"otpStyling"}
                            errorStyle={"otpErrorStyling"}
                        />
                        {(otpError) && (
                            (otpErrorType === "ValidationFailed") ?
                                <div className={"errorMessage"}>
                                    The OTP code you entered is incorrect or expired. Try again or <Button
                                    className={"linkButton"} onClick={resendOTP}>resend a new OTP.</Button>

                                </div>

                                : (otpErrorType === "Empty") ? <div className={"errorMessage"}>
                                    Please enter the OTP sent to {values.root_state.mainMember.cellphone} or <Button
                                    className={"linkButton"} onClick={resendOTP}>resend a new OTP.</Button>

                                </div> : <React.Fragment></React.Fragment>
                        )}
                        {((props.dialogType === "WhatsApp") || (props.dialogType === "validateMe")) ?
                            <div className={"helperMessage"}>
                                Please enter the OTP so that we can WhatsApp your important policy documents to you
                                securely.

                            </div>: (props.dialogType === "saveForLater") ?<div className={"helperMessage"}>
                                Please enter the OTP so that we can SMS your application to your cellphone.
                            </div> :<React.Fragment></React.Fragment>
                        }

                    </DialogContent>
                    <DialogContent className={"dialogContainer dialogActions"}>
                        <LoadingIndicator/>
                        <Button variant="outlined"
                                className={`productButton noOutlineButton popUp funeralSelfServiceButton`}
                                disabled={(loading || promiseInProgress) && true}
                                onClick={resendOTP}>
                            RESEND NEW OTP
                        </Button>
                        <Button variant="contained"
                                className={`productButton backButton  funeralSelfServiceButton push`}
                                onClick={handleCancel}>Cancel</Button>
                        <Button variant="contained" className={`productButton forwardButton  funeralSelfServiceButton`}
                                onClick={handleVerifyOTP}>Submit</Button>
                    </DialogContent>
                </div>)}
        </React.Fragment>
    );
}

export default SidePanel;
