import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import {Grid} from "@material-ui/core";
import {API_CONFIG} from "../../config/api-config";


const useStyles = makeStyles((theme) => ({
    list: {
        maxWidth: 360,
    },
    fullList: {
        width: 'auto',
    },
    appBar: {
        position: 'relative',
    },
    title: {

        flex: 1,
    },
    closeIcon: {
        paddingLeft: '80%',
        float: 'right',
        textAlign: 'right'
    },
    info: {
        float: 'left',
        textAlign: 'left'
    },
    drawerContent: {
        padding: '16px 16px 16px',
    },

    dialogFooter: {
        marginTop: 16,
        fontSize: 10
    }

}));


export default function HelpDrawer(props) {
    const createData = (name, val) => {

        return {name, val};
    }
    const coverRows = [

        createData('Younger than six years (including stillborn babies)', 'R5 000 - R 20 000'),
        createData('Six and older but younger than 14', 'R5 000 - R 50 000'),
        createData('14 and older but younger than 21', 'R5 000 - R 80 000'),
        //createData('Policy end date', 'Your policy ends at retirement or three months from the date at which you leave your current employer.'),
    ]
    const classes = useStyles();
    return (
        <div
            className={classes.list}
            role="presentation"
            onClick={props.toggleDrawerClose}
            onKeyDown={props.toggleDrawerClose}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>

                    {/*<Typography variant="h6" component="h2">Help</Typography>*/}

                    <InfoIcon/>

                    <div className={classes.closeIcon}>
                        <IconButton color="inherit" aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                    </div>


                </Toolbar>
            </AppBar>
            <div className={classes.drawerContent}>
                <Typography variant="h4" component="h4" display="block" gutterBottom align="left"
                            className={"helperHeaderText"}>
                    Whatchu talkin' 'bout, Willis?
                </Typography>
                <Typography variant="h2" component="h2" className={"helperHeaderText"}>{props.title}</Typography>

                {((props.dialogContent.length > 0)) ? (
                    <React.Fragment>

                        {(props.clickedItem === "cashbackBenefit") ? (
                            <React.Fragment><p className={classes.mainContent}>This optional benefit pays you back a
                                proportion of your premiums paid after reaching the following milestones:</p>
                                <ul className={classes.mainContent}>
                                    <li>After you pay your first 12 premiums, you will get 2 premiums back in cash.</li>
                                    <li>Thereafter, you will receive 6 premiums back in cash for every 36 premiums
                                        paid.
                                    </li>
                                </ul>
                            </React.Fragment>) : (props.dialogContent)}
                    </React.Fragment>
                ) : ((props.title === "Children and relatives cover levels") ? (
                    <div>
                        Cover amount limits are set based on a child's age for this product. Please see the table below
                        for a breakdown.
                        <Grid container direction="row" className={"accordionContent"}>

                            <Grid item md={12} xs={12}>

                                <Typography variant={"h4"} component={"h4"} className={"summaryProductNameHeading"}>Metropolitan
                                    Funeral Cover Limits</Typography>
                                {(coverRows).map((coverRow, index) => (
                                    <Grid container justify="space-between" direction="row"
                                          key={`coverLimitRow_${index}`}>
                                        <Grid item md={6} xs={6}><span
                                            className="summaryLabelsHeading">{coverRow.name}</span></Grid>
                                        <Grid item md={6} xs={6}><span
                                            className="summaryLabels">{coverRow.val}</span></Grid>
                                    </Grid>
                                ))}


                            </Grid>
                        </Grid>
                    </div>
                ) : ((props.title === "Child For Life") ? (
                    <div>If your child cannot be insured under children cover, the child can be insured under child for
                        life cover. A child can only be insured on children cover or child for life cover but not both.
                        Financially independent and married children can be insured under child for life cover. You have
                        to let Metropolitan know if you want to do this. Your premium may change if you make this change
                        to your plan.</div>) : (
                    <div>
                        You may cover up to a maximum of 20 lives (including the plan owner).
                        <ul>
                            <li>
                                You may cover up to 3 lives as life partners
                            </li>
                            <li>
                                Your children count as a single life in respect to the overall total, irrespective how
                                many children cover
                            </li>
                            <li>
                                If you choose to cover a child as a Child For Life, that child counts as 1 life
                                on
                                your plan in respect to the total of 20.
                            </li>
                            <li>
                                You may cover up to 4 lives as your parents
                            </li>
                            <li>
                                You may cover up to 9 lives as extended family members
                            </li>
                        </ul>

                    </div>
                )))}
            </div>
            <Divider/>
            <div className={classes.drawerContent}>
                <p className={classes.dialogFooter}>For more information, you can
                    <a
                        href={API_CONFIG.SHOPFRONT_URL.concat("funeral/UGC_funeral_2020_10.pdf")}
                        target="_blank" rel="noreferrer"> view the Metropolitan Funeral User Guide and Contract
                        here.</a>
                </p>
            </div>
        </div>
    );
}
